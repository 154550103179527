<template>
  <div>
    <Toolbar :handle-submit="onSendForm" :handle-reset="resetForm"></Toolbar>
    <MediaObjectForm ref="createForm" :values="item" :errors="violations" />
    <Loading :visible="isLoading" />
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { createHelpers } from 'vuex-map-fields';
import Loading from '../../components/Loading';
import Toolbar from '../../components/Toolbar';
import CreateMixin from '../../mixins/CreateMixin';
import MediaObjectForm from "../../components/mediaobject/Form";

const servicePrefix = 'MediaObject';

const { mapFields } = createHelpers({
  getterType: 'mediaobject/getField',
  mutationType: 'mediaobject/updateField'
});

export default {
  name: 'MediaObjectCreate',
  servicePrefix,
  mixins: [CreateMixin],
  components: {
    MediaObjectForm,
    Loading,
    Toolbar,
  },
  data() {
    return {
      item: {}
    };
  },
  computed: {
    ...mapFields(['error', 'isLoading', 'created', 'violations'])
  },
  methods: {
    ...mapActions('mediaobject', ['create', 'reset'])
  }
};
</script>
